import '#vendor/fontawesome-pro-web/css/all.css'

import $ from 'jquery';
import * as _ from 'lodash'
import 'core-js';
import 'bootstrap'

import '../jquery-ujs.js'
// import 'babel-polyfill'
import 'element-closest'

window['jQuery'] = $;
window['$'] = $; // fix captcha change image bug
window['lodash'] = _;

$.rails.href = function(element) {
  return element.data('href') || element[0].href;
};

import '../_stimulus';
import '../frontend';
